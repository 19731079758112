<template>
  <lf-modal :title="title" :close="close" large>
    <template v-slot:content>
      <loader :isLoading="loading" />
      <form @submit.prevent="onSubmit" class="min-h-[950px]">
        <div class="p-6 space-y-5" v-show="!loading">
          <lf-h3>
            {{ $t("ACCOUNT.USER_INFO") }}
          </lf-h3>
          <div class="grid grid-cols-2 gap-4">
            <lf-input
              :placeholder="$t('COMMON.FIRST_NAME')"
              :value="model.first_name"
              name="first_name"
            />
            <lf-input
              :placeholder="$t('COMMON.LAST_NAME')"
              :value="model.last_name"
              name="last_name"
            />
            <div
              :class="{
                'mb-6': model.pending_email
              }"
            >
              <lf-input
                :placeholder="$t('COMMON.EMAIL')"
                :value="model.email"
                name="email"
              />
              <span v-if="model.pending_email" class="ml-2">
                {{
                  $t("ACCOUNT.PENDING_EMAIL", { email: model.pending_email })
                }}
              </span>
            </div>
            <lf-checkbox-dropdown
              :class="{ error: formErrors.roles }"
              v-model="formValues.roles"
              :placeholder="placeHolderText"
              :options="getUserRoles(roles)"
              name="roles"
              :disabled="!isRoleEditable"
              valueIsOption
              :optionFormatter="wordsFirstLetterToUpper"
              width-full
              dropdown-width-full
            />
            <lf-input
              v-if="showPhoneInput"
              :placeholder="$t('COMMON.PHONE')"
              :value="model.phone"
              name="phone"
            />
          </div>
          <lf-h3 class="flex flex-col">
            <span>{{ $t("ACCOUNT.CALENDAR_LINK") }}</span>
            <span class="font-normal text-gray-400 text-sm">
              {{ $t("ACCOUNT.CALENDAR_LINK_SUBTITLE") }}
            </span>
          </lf-h3>
          <lf-input
            :placeholder="$t('ACCOUNT.CALENDAR_LINK')"
            :value="model.calendar_link"
            name="calendar_link"
          />
          <lf-h3>
            {{ $t("PRESETS.TITLE") }}
          </lf-h3>
          <folder-tabs
            v-if="!loading && tabs.length"
            :tabs="tabs"
            :active-tab-id="activeTabId"
            :assignedPreset="userPresetId"
            @change="handlePresetChange"
            class="my-2"
          >
            <div class="bg-white px-4 space-y-2">
              <user-modal-clients-list
                v-if="auth.isLendflowUser && !activeClient"
                v-model="assignedClientIds"
                data-cy="user-modal-clients-list"
                :title="$t('COMMON.ASSIGNMENT')"
                has-border
                :disabled="disableEdit"
              />
              <assign-workflow-templates
                v-model="assignedWorkflowTemplateIds"
                :assigned-client-ids="assignedClientIds"
                :preset-wfb-templates="
                  model.permissions_preset?.assigned_workflow_templates
                "
                data-cy="assign-workflow-template"
                :disabled="disableEdit"
              />
            </div>
          </folder-tabs>
        </div>
        <div
          v-show="!loading"
          class="border-t border-gray-200 p-6 flex justify-end"
        >
          <outline-button @click="close" class="mr-3">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button type="submit" :disabled="isSubmitting">
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import { callStoreAction } from "@/helpers/vee-validate";
import type { PropType } from "vue";
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { usePromiseWrapper } from "@/hooks/common";
import { useNotification } from "@/hooks/notifications";
import { useAuth } from "@/hooks/auth";
import { useClients } from "@/hooks/clients";
import {
  CLIENT_ADMIN,
  CLIENT_UNDERWRITER,
  FUNDER_ROLE_GROUP,
  FUNDING_ADVISOR,
  ROLES_MAP,
  USER_BLUEPRINT
} from "@/helpers/constants";
import type { Role } from "@/models/options";
import type { IUser } from "@/models/users";
import type { PermissionsPreset } from "@/models/permissionsPreset";

import { wordsFirstLetterToUpper, getUserRoles } from "@/helpers/formatting";
import FolderTabs from "@/components/ui/FolderTabs.vue";
import UserModalClientsList from "@/views/profile/modals/UserModalClientsList.vue";
import AssignWorkflowTemplates from "@/components/presets/presetModal/AssignWorkflowTemplates.vue";
import usePermissionsPresetStore from "@/stores/permissionsPreset";
import compact from "lodash/compact";
import { useUsersStore } from "@/stores/users";

const NO_PRESET_ID = "no-preset";

const props = defineProps({
  model: {
    type: Object as PropType<typeof USER_BLUEPRINT>,
    default: () => USER_BLUEPRINT
  },
  employees: {
    type: Boolean,
    default: false
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});

const permissionsPresetStore = usePermissionsPresetStore();
const { all } = storeToRefs(permissionsPresetStore);
const { getAll } = permissionsPresetStore;
const { fetchWrapper: getAllWrapper, loading: allPresetsLoading } =
  usePromiseWrapper(getAll);
const { activeClient } = useClients();
const store = useStore();
const { t } = useI18n();
const { params, name, query } = useRoute();
const { showMessage } = useNotification();
const auth = useAuth();
const usersStore = useUsersStore();

const NO_PRESET_TAB = {
  id: NO_PRESET_ID,
  name: t("PRESETS.NO_PRESET"),
  assigned_client_ids: [],
  assigned_workflow_template_ids: []
};

const isEditMode = !!props.model.id;
const assignedClientIds = ref<string[]>([]);
const assignedWorkflowTemplateIds = ref<string[]>([]);

const disableEdit = computed(
  () =>
    activeTabId.value !== NO_PRESET_ID &&
    (activeTabId.value !== props.model.permissions_preset?.id ||
      props.model.permissions_preset?.is_published)
);

const title = ref(
  isEditMode
    ? name === "Employees"
      ? t("EMPLOYEES.EDIT_EMPLOYEE")
      : t("ORGANIZATION.EDIT_USER")
    : name === "Employees"
      ? t("EMPLOYEES.ADD_BTN")
      : t("ORGANIZATION.ADD_USER")
);

const currentUser = computed<IUser>(() => store.getters["auth/user"]);
const isCurrentUser = computed(() => currentUser.value?.id === props.model?.id);

const loading = computed(() => allPresetsLoading.value || isSubmitting.value);
const userPresetId = computed(() => props.model.permissions_preset?.id);

const componentIsForEmployees = computed(
  () => props.employees || name === "Employees"
);

const placeHolderText = computed(() =>
  t(
    componentIsForEmployees.value
      ? "ACCOUNT.EMPLOYEE_ROLES"
      : "ACCOUNT.USER_ROLES"
  )
);

const roles = computed(() => {
  let roles: Role[] = store.getters["auth/manageUserRoles"](
    componentIsForEmployees.value
  );

  if (!componentIsForEmployees.value) {
    roles = [...roles, CLIENT_ADMIN, ...FUNDER_ROLE_GROUP];
  }

  if (activeClient.value?.can_create_underwriter) {
    roles = [...roles, CLIENT_UNDERWRITER];
  }

  return roles;
});

const formValues = ref({ ...props.model });

const activeTabId = ref(props.model?.permissions_preset_id ?? NO_PRESET_ID);

const {
  handleSubmit,
  isSubmitting,
  errors: formErrors,
  values
} = useForm({
  initialValues: formValues.value
});

const showPhoneInput = computed(
  () => values.roles?.includes(ROLES_MAP[FUNDING_ADVISOR] as Role)
);

const presetIsPublished = ref(false);

const tabs = ref<Partial<PermissionsPreset>[]>([]);

const isRoleEditable = computed(
  () => !isCurrentUser.value && (auth.isClientAdmin || auth.isLendflowUser)
);

const clientId = computed(() =>
  auth.isClient ? activeClient.value?.id : params.id
);

const handlePresetChange = (presetId: string | undefined) => {
  if (!presetId) {
    return;
  }
  activeTabId.value = presetId;
  const selectedPreset = tabs.value.find((e) => e.id === presetId);

  if (!props.employees && params.id) {
    assignedClientIds.value = [params.id as string];
  } else {
    assignedClientIds.value =
      selectedPreset?.assigned_clients?.map((client) => client.id) ?? [];
  }

  assignedWorkflowTemplateIds.value = compact(
    selectedPreset?.assigned_workflow_template_ids
  );
  presetIsPublished.value = selectedPreset?.is_published ?? false;
};
const onSubmit = handleSubmit(async (values, actions) => {
  values.roles = values.roles?.reduce((acc, curr) => {
    return [
      ...acc,
      (Object.entries(ROLES_MAP).find(
        ([, value]) => value === curr
      )?.[0] as Role) || curr
    ];
  }, [] as Role[]);

  const userData = presetIsPublished.value
    ? { ...values, permissions_preset_id: activeTabId.value }
    : {
        ...values,
        assigned_client_ids: assignedClientIds.value,
        assigned_workflow_template_ids: assignedWorkflowTemplateIds.value
      };

  if (!props.employees) {
    delete userData.assigned_client_ids;
  }

  let payload: Record<string, unknown>;
  if (isEditMode) {
    payload = {
      user: presetIsPublished.value
        ? { ...userData, permissions_preset_id: activeTabId.value }
        : userData,
      userId: props.model.id
    };
  } else {
    payload = userData;
    if (!props.employees) {
      payload.client_id = auth.isClientAdmin
        ? activeClient.value?.id
        : params.id;
    }
  }

  if (props.employees) {
    payload.appendCreated = true;
  }

  try {
    await callStoreAction(
      payload,
      actions,
      isEditMode ? "updateUser" : "create",
      usersStore
    );
  } catch {
    return;
  }

  if (props.employees) {
    await usersStore.getEmployees({ params: query });
  } else {
    await usersStore.getUsers({
      clientId: clientId.value as string,
      params: query
    });
  }
  if (isEditMode && values.email && props.model.email !== values.email) {
    showMessage(t("AUTH.VERIFICATION_EMAIL_SENT"));
  }

  props.close();
});

watch(
  formValues,
  (val) => {
    formValues.value.roles = getUserRoles(val.roles || []) as Role[];
  },
  { immediate: true }
);

onMounted(async () => {
  const requestParams: Record<string, string | string[]> = {};
  if (!props.employees) {
    requestParams.client_id = activeClient.value?.id ?? params.id;
  }

  await getAllWrapper(requestParams);

  if (auth.isLendflowUser) {
    assignedClientIds.value = props.employees
      ? props.model.permissions_preset?.assigned_clients.map(
          (client) => client.id
        ) ?? []
      : [params.id as string];
  }

  if (!props.model.permissions_preset_id) {
    tabs.value = [NO_PRESET_TAB, ...all.value.data];
    activeTabId.value = NO_PRESET_ID;
    presetIsPublished.value = false;
    return;
  }

  if (!props.model.permissions_preset?.id) {
    tabs.value = [NO_PRESET_TAB, ...all.value.data];
  } else {
    tabs.value = props.model.permissions_preset?.is_published
      ? [
          NO_PRESET_TAB,
          props.model.permissions_preset,
          ...all.value.data.filter(
            (preset) => preset.id !== props.model.permissions_preset?.id
          )
        ]
      : [props.model.permissions_preset, ...all.value.data];
  }

  assignedWorkflowTemplateIds.value = compact(
    props.model.permissions_preset?.assigned_workflow_template_ids
  );
  activeTabId.value = props.model.permissions_preset_id;
  presetIsPublished.value = !!props.model.permissions_preset?.is_published;
});
</script>

<style scoped lang="postcss">
.error {
  @apply hover:border-red-600 focus:border-red-600 focus-within:ring-transparent border border-red-600;
}
</style>
