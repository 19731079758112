import { defineStore } from "pinia";
import i18n from "@/i18n";

import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type {
  PermissionsPreset,
  PermissionsPresetFilter
} from "@/models/permissionsPreset";
import permissionsPresetService from "@/services/modules/permissionsPreset";

interface UsePermissionsPresetState {
  all: IPaginatedResponse<PermissionsPreset>;
  userPreset: PermissionsPreset | null;
}

const usePermissionsPresetStore = defineStore("permissionsPreset", {
  state: (): UsePermissionsPresetState => ({
    all: {
      data: [],
      meta: {} as IResponseMeta,
      links: {} as IResponseLinks
    },
    userPreset: null
  }),
  actions: {
    async getAll(params: Partial<PermissionsPresetFilter> = {}) {
      this.all = await permissionsPresetService.getAll(params);
    },
    async getSingle(id: string) {
      this.userPreset = await permissionsPresetService.getSingle(id);
    },
    async createPreset(params: Partial<PermissionsPreset>) {
      const newPreset = await permissionsPresetService.create(params);
      this.all.data.unshift(newPreset);
    },
    async deletePreset(presetId: string) {
      await permissionsPresetService.delete(presetId);
      this.all.data = this.all.data.filter((preset) => preset.id !== presetId);
    },
    async duplicatePreset(params: Partial<PermissionsPreset>) {
      const payload = {
        ...params,
        name: `${params.name} ${i18n.global.t("COMMON.APPEND_COPY_TEXT")}`
      };

      const duplicatedPreset = await permissionsPresetService.create(payload);
      this.all.data.unshift(duplicatedPreset);
    },
    async updatePreset(params: Partial<PermissionsPreset>) {
      if (!params.id) {
        return;
      }

      const updatedPreset = await permissionsPresetService.update(
        params,
        params.id
      );

      const presetIndexToReplace = this.all.data.findIndex(
        (preset) => preset.id === params.id
      );

      if (presetIndexToReplace !== -1 && presetIndexToReplace !== undefined) {
        this.all.data[presetIndexToReplace] = updatedPreset;
      }
    }
  }
});

export default usePermissionsPresetStore;
