<template>
  <action-menu-wrapper
    :items="menuItems"
    teleport
    :offset="{ left: isClient ? -100 : -150 }"
  />

  <user-modal
    v-if="showUserModal"
    :model="model"
    :employees="menuType === 'employees'"
    :close="() => (showUserModal = false)"
  />

  <funder-user-modal
    v-if="showFunderUserModal"
    :model="model"
    :close="() => (showFunderUserModal = false)"
  />

  <confirm-modal
    v-if="showDeleteModal"
    :title="deleteModalInfo.title"
    :description="deleteModalInfo.description"
    :confirm-button="$t('COMMON.DELETE')"
    :close="() => (showDeleteModal = false)"
    confirm-button-type="error"
    @confirm="deleteModalInfo.handler"
  />
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { formatUserFullName } from "@/helpers/formatting";
import { useAuth, useImpersonate } from "@/hooks/auth";
import { SUPER_ADMIN } from "@/helpers/constants";
import { useUsersStore } from "@/stores/users";
import { useClients } from "@/hooks/clients";

import ActionMenuWrapper from "@/components/ui/ActionMenuWrapperV2.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import UserModal from "@/views/profile/modals/UserModal.vue";
import FunderUserModal from "@/views/profile/modals/FunderUserModal.vue";

import type { IUser } from "@/models/users";
import type { MenuItem } from "@/components/ui/ActionMenuWrapperV2.vue";

type MenuType = "employees" | "clients" | "funders";

const props = defineProps<{
  model?: IUser;
  menuType?: MenuType;
}>();

const { t } = useI18n();
const { getters, dispatch, commit } = useStore();
const { isSuperAdmin, isAdmin, isFunderAdmin, isClient } = useAuth();
const usersStore = useUsersStore();
const { isClientOwner, promoteToOwner } = useClients();
const { alreadyImpersonatingSomeone } = useImpersonate();

const showUserModal = ref(false);
const showFunderUserModal = ref(false);
const showDeleteModal = ref(false);

const userId = computed(() => getters["auth/user"]["id"]);

const menuItems = computed(() => {
  const editDisabled =
    !props.model ||
    props.model.id === userId.value ||
    (props.model.roles?.includes(SUPER_ADMIN) && !isSuperAdmin);

  const showPromote =
    props.menuType === "clients" && isAdmin && !isClientOwner(props.model);

  const showDelete = props.menuType !== "funders" || isAdmin || isFunderAdmin;

  const items: (MenuItem | boolean)[] = [
    {
      label: t("COMMON.EDIT"),
      disabled: editDisabled,
      handler: () => {
        if (props.menuType === "funders") {
          showFunderUserModal.value = true;
        } else {
          showUserModal.value = true;
        }
      }
    },
    showPromote && {
      label: t("COMMON.PROMOTE_TO_OWNER"),
      handler: () => promoteToOwner(props.model?.id)
    },
    isAdmin && {
      label: t("COMMON.IMPERSONATE"),
      handler: impersonate
    },
    showDelete && {
      label: t("COMMON.DELETE"),
      handler: () => (showDeleteModal.value = true)
    }
  ];

  return items.filter(Boolean) as MenuItem[];
});

async function deleteUser() {
  if (!props.model) {
    return;
  }
  if (props.menuType === "funders") {
    await dispatch("funders/deleteUser", {
      userId: props.model.id
    });
  } else {
    await usersStore.deleteUser({ userId: props.model.id });
  }
}

const deleteModalInfo = computed(() => {
  const title =
    props.menuType === "funders"
      ? `${t("COMMON.DELETE")} ${t("COMMON.FUNDER")}`
      : props.menuType === "employees"
        ? t("EMPLOYEES.DELETE_EMPLOYEE")
        : t("ORGANIZATION.DELETE_USER");

  const description = `${formatUserFullName(props.model)}${t(
    "ORGANIZATION.DELETE_WARNING"
  )}`;

  return {
    title,
    description,
    handler: deleteUser
  };
});

const impersonate = async () => {
  if (alreadyImpersonatingSomeone.value) {
    return;
  }
  await dispatch("auth/impersonateUser", {
    userId: props.model?.id
  });
  await dispatch("auth/getAuthDetails");
  commit("resetHistoryStack", null, { root: true });
  commit("clients/unsetActive");
};
</script>
