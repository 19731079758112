import type { IPaginatedResponse, IResponse } from "@/models/common";
import type {
  PermissionsPreset,
  PermissionsPresetFilter
} from "@/models/permissionsPreset";
import { axiosClient } from "@/services/client";
import { formatString } from "@/helpers/formatting";
import endpoints from "@/services/endpoints";

class PermissionsPresetService {
  async getAll(params: Partial<PermissionsPresetFilter>) {
    const response = await axiosClient.get<
      IPaginatedResponse<PermissionsPreset>
    >(endpoints.PRESETS.ALL, { params });

    return response.data;
  }

  async getSingle(id: string) {
    const response = await axiosClient.get<IResponse<PermissionsPreset>>(
      formatString(endpoints.PRESETS.SINGLE, id)
    );

    return response.data.data;
  }

  async create(preset: Partial<PermissionsPreset>) {
    const response = await axiosClient.post<{ data: PermissionsPreset }>(
      endpoints.PRESETS.ALL,
      preset
    );
    return response.data.data;
  }

  async delete(presetId: string) {
    await axiosClient.delete(formatString(endpoints.PRESETS.SINGLE, presetId));
  }

  async update(preset: Partial<PermissionsPreset>, presetId: string) {
    const response = await axiosClient.put<{ data: PermissionsPreset }>(
      formatString(endpoints.PRESETS.SINGLE, presetId),
      preset
    );
    return response.data.data;
  }
}

const permissionsPresetService = new PermissionsPresetService();
export default permissionsPresetService;
