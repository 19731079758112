<template>
  <div class="flex flex-col space-y-4">
    <loader :isLoading="clientsLoading" />
    <div class="flex justify-between">
      <lf-h4>
        {{ title || $t("ACCOUNT.ASSIGNED_CLIENTS") }}
      </lf-h4>
      <lf-link
        :underlined="false"
        :disabled="disabled"
        data-cy="client-select-all"
        @click="selectAll"
      >
        {{
          isSelectedAllClients
            ? $t("COMMON.DESELECT_ALL")
            : $t("COMMON.SELECT_ALL")
        }}
      </lf-link>
    </div>
    <div class="flex flex-col m-0-25" :class="{ 'border-b': hasBorder }">
      <search-input
        no-padding
        no-margin
        v-model="clientSearch"
        name="searchClients"
        :searchTerm="$t('COMMON.CLIENTS')"
      />
      <div
        class="flex flex-col space-y-2 h-65 overflow-auto pt-3"
        :class="{ 'border-x px-3': hasBorder }"
      >
        <div v-if="!clients.length">
          {{ $t("COMMON.NO_RESULTS") }}
        </div>
        <div v-for="client in clients" :key="client.id" class="flex space-x-4">
          <input
            class="m-1"
            type="checkbox"
            :id="`client-${client.id}`"
            v-model="selectedClients"
            :value="client.id"
            :disabled="disabled"
          />
          <label :for="`client-${client.id}`">
            {{ client.name }}
          </label>
        </div>
      </div>
      <div
        v-if="
          paginatedOptionsMeta.current_page !== paginatedOptionsMeta.last_page
        "
        class="flex items-center justify-center py-3"
        :class="{ 'border-x': hasBorder }"
      >
        <lf-link
          @click="getClients(false)"
          :disabled="disabled"
          data-cy="load-more-button"
        >
          {{ $t("COMMON.LOAD_MORE") }}
        </lf-link>
      </div>
      <error-message
        name="assigned_clients"
        class="pl-2 text-xxs sm:text-xs text-red-700"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed, onMounted, watch } from "vue";
import type { IClient } from "@/models/clients";
import { ErrorMessage } from "vee-validate";

import clientsApiService from "@/services/modules/clients";
import type { IPaginatedResponse, PaginatedOptionsMeta } from "@/models/common";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
    required: true
  },
  title: {
    type: String,
    default: ""
  },
  hasBorder: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const paginatedOptionsMeta = ref<PaginatedOptionsMeta>({
  current_page: 0,
  last_page: null
});

const isSelectedAllClients = ref(false);

const selectedClients = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: string[]) {
    isSelectedAllClients.value = newValue.length === clients.value.length;
    emit("update:modelValue", newValue);
  }
});
const clientsLoading = ref(false);
const clients = ref<IClient[]>([]);
const clientSearch = ref("");

const getClients = async (reset = false) => {
  const { current_page, last_page } = paginatedOptionsMeta.value;
  if (current_page === last_page) {
    return;
  }
  const pageToGet = current_page + 1;

  try {
    clientsLoading.value = true;
    const response = (await clientsApiService.getClients({
      page: pageToGet,
      search: clientSearch.value || null
    })) as IPaginatedResponse<IClient>;

    paginatedOptionsMeta.value.current_page = response.meta.current_page;
    paginatedOptionsMeta.value.last_page = response.meta.last_page;
    clients.value = reset
      ? response.data
      : [...clients.value, ...response.data];

    isSelectedAllClients.value =
      selectedClients.value.length === clients.value.length;
  } finally {
    clientsLoading.value = false;
  }
};

const selectAll = () => {
  if (props.disabled) {
    return;
  }
  if (!isSelectedAllClients.value) {
    selectedClients.value = clients.value.map((client) => client.id);
    isSelectedAllClients.value = true;
  } else {
    selectedClients.value = [];
    isSelectedAllClients.value = false;
  }
};

watch(clientSearch, () => {
  paginatedOptionsMeta.value.current_page = 0;
  paginatedOptionsMeta.value.last_page = null;
  getClients(true);
});

onMounted(getClients);
</script>
