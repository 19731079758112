<template>
  <lf-modal :title="title" :close="close">
    <template #content>
      <loader :isLoading="isSubmitting" />
      <form @submit.prevent="onSubmit">
        <div class="flex-col p-6">
          <lf-input
            :placeholder="$t('COMMON.FIRST_NAME')"
            :value="model.first_name"
            name="first_name"
          />
          <lf-input
            :placeholder="$t('COMMON.LAST_NAME')"
            :value="model.last_name"
            name="last_name"
          />
          <div
            :class="{
              'mb-6': model.pending_email
            }"
          >
            <lf-input
              :placeholder="$t('COMMON.EMAIL')"
              :value="model.email"
              name="email"
            />
            <span v-if="model.pending_email" class="ml-2">
              {{ $t("ACCOUNT.PENDING_EMAIL", { email: model.pending_email }) }}
            </span>
          </div>
          <lf-checkbox-dropdown
            v-model="formValues.roles"
            name="roles"
            valueIsOption
            width-full
            :placeholder="$t('FUNDERS.FUNDER_ROLE')"
            :options="getUserRoles(allRoles)"
            :disabled="!isRoleEditable"
            :optionFormatter="wordsFirstLetterToUpper"
          />
        </div>
        <div class="border-t border-gray-200 p-6 flex justify-end">
          <outline-button @click="close" class="mr-3">
            {{ $t("COMMON.CANCEL") }}
          </outline-button>
          <primary-button type="submit" :disabled="isSubmitting">
            {{ $t("COMMON.SAVE") }}
          </primary-button>
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import LfModal from "@/components/ui/Modal.vue";

import { dispatchAction } from "@/helpers/vee-validate";
import type { PropType } from "vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useNotification } from "@/hooks/notifications";
import { useRoute } from "vue-router";
import { FUNDER_ADMIN, USER_BLUEPRINT, ROLES_MAP } from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import type { IUser } from "@/models/users";
import type { Role } from "@/models/options";
import { wordsFirstLetterToUpper, getUserRoles } from "@/helpers/formatting";

const props = defineProps({
  model: {
    type: Object as PropType<IUser>,
    default: () => USER_BLUEPRINT
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  }
});
const store = useStore();
const { t } = useI18n();
const { showMessage } = useNotification();
const route = useRoute();

const formValues = ref({ ...props.model });

const { handleSubmit, isSubmitting } = useForm({
  initialValues: formValues.value
});

const { isFunderAdmin, isSuperAdmin, isAdmin } = useAuth();

const allRoles = computed(() => [
  ...new Set([...roles.value, ...props.model.roles])
]);

const user = computed<IUser>(() => store.getters["auth/user"]);

const isEditMode = computed(() => !!props.model.id);

const title = computed(() =>
  isEditMode.value ? t("FUNDERS.EDIT_FUNDER") : t("FUNDERS.ADD_NEW_FUNDER")
);

const roles = computed(() => {
  const funderUserRoles = store.getters["auth/funderUserRoles"] as Role[];

  return [FUNDER_ADMIN, ...funderUserRoles];
});

const isCurrentUser = computed(() => user.value?.id === props.model?.id);

const isRoleEditable = computed(
  () =>
    !isCurrentUser.value && //users can't edit their own role
    (isFunderAdmin || isSuperAdmin || isAdmin)
);

const onSubmit = handleSubmit(async (values, actions) => {
  values.roles = values.roles?.reduce((acc, curr) => {
    return [
      ...acc,
      (Object.entries(ROLES_MAP).find(
        ([, value]) => value === curr
      )?.[0] as Role) || curr
    ];
  }, [] as Role[]);

  const payload: Record<string, unknown> = isEditMode.value
    ? {
        data: values,
        userId: props.model.id
      }
    : values;

  payload.funder_id =
    route.name === "FunderOrganization"
      ? user.value.funder?.id
      : route.params.id;

  const action = isEditMode.value ? "funders/editUser" : "funders/createUser";
  try {
    await dispatchAction(payload, actions, action);
  } catch {
    return;
  }
  await store.dispatch("funders/checkHasFunderAdmin");

  if (isEditMode.value && props.model.email !== values.email) {
    showMessage(t("AUTH.VERIFICATION_EMAIL_SENT"));
  }

  props.close();
});

watch(
  formValues,
  (val) => {
    formValues.value.roles = getUserRoles(val.roles || []) as Role[];
  },
  { immediate: true }
);
</script>
